import { useEffect, useState } from "react";

import Cookies from "js-cookie";

import { usePiano, usePianoInit } from "@sciam/piano/react";
import { useAuth } from "~/features/auth";
import { useEnvironment } from "~core/hooks/use-environment";
import { usePage } from "~core/hooks/use-page";
import { usePageQuery } from "~core/hooks/use-page-query";
import { cx } from "~utils";
import { AUTH0_ERROR_STATES } from "../constants";

import { useLoginUrl } from "../hooks/use-login-url";
import { Loader } from "./Loader";

import { clearEntitlementsCache } from "~/features/access";

import usePianoAuthSync from "~features/piano/hooks/use-piano-auth-sync";
import styles from "./Content.module.css";

/**
 * @param {{ action: "login"|"register"|"logout"|"callback" }} params
 */
export function AuthPageContent({ action }) {
  const actionMap = {
    login: <Login />,
    register: <Register />,
    logout: <Logout />,
    callback: <Callback />,
  };

  return actionMap[action] || <Login />;
}

const BaseContent = ({ className, children }) => {
  return <div className={cx(styles.content, className)}>{children}</div>;
};

function Login() {
  const { pageData } = usePage();
  const returnTo = pageData?.props?.returnTo;

  const { auth } = useEnvironment();
  const { isLoggedIn, isLoading, login } = useAuth();
  const fallbackAuthorizeUrl = useLoginUrl();

  // 2.5s delay before redirecting so the user perceives what's happening
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    clearTimeout(timer);
    if (isLoading) return;
    setTimer(
      setTimeout(() => {
        // When the user is already logged in, calling login() is a no-op.
        // Let's redirect them back to the site instead.
        if (isLoggedIn) {
          window.location.href = returnTo?.path || "/";
        } else {
          login(returnTo?.path || "/");
        }
      }, 2500),
    );
  }, [auth.provider, isLoggedIn, isLoading]);

  return (
    <BaseContent className={styles.login}>
      <Loader />
      <h1>{isLoggedIn ? "Already logged in" : "Opening login page..."}</h1>
      <p>
        If you are not redirected automatically,{" "}
        <a
          // This fallback Auth0 URL is used in case JavaScript or React breaks
          // - ssr/pre-hydration - redirects back to the prod homepage
          // - post-hydration - redirects to the current tab's homepage
          href={fallbackAuthorizeUrl}
          onClick={(e) => {
            e.preventDefault();
            login(returnTo?.path || "/");
          }}
        >
          click here
        </a>
        .
      </p>
    </BaseContent>
  );
}

function Register() {
  const { pageData } = usePage();
  const returnTo = pageData?.props?.returnTo;

  const { auth } = useEnvironment();
  const { isLoading, isLoggedIn, register } = useAuth();
  const fallbackAuthorizeUrl = useLoginUrl();

  // Redirect to registration page after 2.5s
  // If the user is logged in, do nothing
  useEffect(() => {
    if (isLoggedIn || isLoading) return;
    register(returnTo?.path || "/");
  }, [isLoading, isLoggedIn, auth.provider]);

  return (
    <BaseContent className={styles.register}>
      {isLoggedIn ? (
        <>
          <h1>Already Have an Account</h1>
          <p>
            You are already created your account and signed in. If you would like to create a new
            account, please <a href="/logout/?returnTo=/register/">log out</a> first.
          </p>
        </>
      ) : (
        <>
          <Loader />
          <h1>Create Your Account</h1>
          <p>
            If you are not redirected automatically,{" "}
            <a
              // Same as the fallback in the <Login> component but with a different screen_hint
              href={fallbackAuthorizeUrl + "&screen_hint=signup"}
              onClick={(e) => {
                e.preventDefault();
                register(returnTo?.path || "/");
              }}
            >
              click here
            </a>
            .
          </p>
        </>
      )}
    </BaseContent>
  );
}

function Logout() {
  const { isLoading: isPianoLoading } = usePiano();
  const { isLoggedIn, logout, isLoading } = useAuth();
  const { auth } = useEnvironment();
  const { pageData } = usePage();
  const returnTo = pageData?.props?.returnTo;

  // Users who click the "Log out" button will be logged out before they reach this page
  // Users who navigate here might require calling the Auth0 logout function
  const [waitForAuth0, setWaitForAuth0] = useState(true);
  useEffect(() => {
    clearEntitlementsCache();

    if (isLoggedIn) {
      setWaitForAuth0(true);
      logout(returnTo?.path || "/");
    } else if (!isLoading) {
      setWaitForAuth0(false);
    }
  }, [isLoggedIn, isLoading, auth?.provider]);

  // Express already clears out Piano session cookies, and that's likely all we need.
  // However, this second client-side logout will make sure Piano cleans any remaining state up
  // e.g., if Piano sets some LocalStorage keys
  const [waitForPiano, setWaitForPiano] = useState(true);
  usePianoInit(async () => {
    // Turns out this function returns a promise
    await window.tp?.pianoId?.logout();
    setWaitForPiano(false);
  });

  // Fallback if the above hook gets caught in Piano JS purgatory
  useEffect(() => {
    if (isPianoLoading) return;
    setTimeout(() => {
      // Forcibly log out just in case
      Cookies.remove("__utp");
      Cookies.remove("__tac");

      setWaitForPiano(false);
    }, 5000);
  }, [isPianoLoading]);

  // Wait for client-side logout actions to complete before redirecting
  const allowRedirection = !waitForAuth0 && !waitForPiano;

  // Redirect back to the site
  // Auth0 should already be doing this, but this is a fallback
  useEffect(() => {
    if (!allowRedirection) return;
    // Return to either a value derived from the returnTo query param or go back to the homepage
    window.location.href = returnTo?.path || "/";
  }, [allowRedirection]);

  // Clear out the last seen entitlement resources
  useEffect(() => {
    localStorage.removeItem("_sciamLastSeenResources");
  }, []);

  return (
    <BaseContent className={styles.logout}>
      <Loader />
      <h1>{allowRedirection ? "Signed Out" : "Signing Out"}</h1>
      {allowRedirection ? (
        <p>
          If you are not redirected automatically, <a href={returnTo?.path || "/"}>click here</a>.
        </p>
      ) : (
        <p>You are being signed out...</p>
      )}
    </BaseContent>
  );
}

function Callback() {
  const { user, isLoading } = useAuth();
  const { status } = usePianoAuthSync();
  const query = usePageQuery();
  const { pageData } = usePage();
  const { returnTo, error, errorDescription } = pageData?.props || {};

  const [waiting, setWaiting] = useState(true);

  // 99% of the time, onRedirectCallback in the Auth0Provider will redirect the page
  // after usePianoAuthSync() is complete.
  // This is a fallback for when that doesn't happen.
  // After the redirect we'll retry setting the Piano JWT.
  useEffect(() => {
    if (waiting) setTimeout(() => setWaiting(false), 6000);
    if (isLoading || !user || status === "loading") return;
    setTimeout(() => {
      window.location.href = returnTo?.path || "/";
    }, 1000);
  }, [isLoading, user, status]);

  return (
    <BaseContent className={styles.callback}>
      {error || query.error ? (
        <>
          <h1>{AUTH0_ERROR_STATES[error || query.error] || "Problem Signing In"}</h1>
          <hr />
          {errorDescription || query.error_description ? (
            <>
              <p>{errorDescription || query.error_description}</p>
              <p>
                Please return to the <a href="/">homepage</a>.
              </p>
            </>
          ) : (
            <>
              <p>There was a problem signing you in.</p>
              <p>
                Please return to the <a href="/">homepage</a>.
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <Loader />
          <h1>{AUTH0_ERROR_STATES.success}</h1>
          <p>You will be redirected back to the site shortly.</p>
          {status === "loading" && waiting ? (
            <p>Please wait...</p>
          ) : (
            <p>
              <a href={returnTo?.path || "/"}>
                Click here if you are not redirected automatically.
              </a>
            </p>
          )}
        </>
      )}
    </BaseContent>
  );
}

export default AuthPageContent;
